import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import HeaderNav from '../HeaderNav/HeaderNav';
import FooterNav from '../FooterNav/FooterNav.jsx';
import Footer from '../Footer/Footer';

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

import AboutPage from '../AboutPage/AboutPage';
import UserPage from '../UserPage/UserPage';
import InfoPage from '../InfoPage/InfoPage';
import LoginPage from '../LoginPage/LoginPage';
import RegisterPage from '../RegisterPage/RegisterPage';
import AddItemPage from '../AddItem/AddItem.jsx';
import ReviewAddItemPage from '../AddItemReview/AddItemReview.jsx';
import ClosetPage from '../Closet/Closet.jsx';
import ClosetDetails from '../ClosetDetails/ClosetDetails.jsx';
import ClosetDetailsEdit from '../ClosetDetailsEdit/ClosetDetailsEdit.jsx';
import MakeOutfitPage from '../MakeOutfit/MakeOutfit.jsx';
import OutfitCommentPage from '../MakeOutfitComment/MakeOutfitComment.jsx';
import OutfitReviewPage from '../MakeOutfitReview/MakeOutfitReview.jsx';
import OutfitsPage from '../Outfits/Outfits.jsx';

import './App.css';

function App() {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);

  useEffect(() => {
    dispatch({ type: 'FETCH_USER' });
  }, [dispatch]);

  return (
    <Router>
      <div>
        <HeaderNav />

        <Switch>
          {/* Visiting localhost:3000 will redirect to localhost:3000/home */}
          <Redirect exact from="/" to="/login" />

          {/* Visiting localhost:3000/about will show the about page. */}
          <Route
            // shows AboutPage at all times (logged in or not)
            exact
            path="/about"
          >
            <AboutPage />
          </Route>

          <Route
            // shows addItem page
            exact
            path="/addItem"
          >
            {!user.id ? (
              // If the user is already logged in,
              // redirect them to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the registration page

              <AddItemPage />
            )}
          </Route>

          <Route
            // shows addItem page
            exact
            path="/addItemReview"
          >
            <ReviewAddItemPage />
          </Route>

          <Route
            // shows Closet page
            exact
            path="/closet"
          >
            {!user.id ? (
              // If the user is already logged in,
              // redirect them to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the registration page

              <ClosetPage />
            )}
          </Route>

          <Route
            // shows Closet page
            exact
            path="/details/:id"
          >
            {!user.id ? (
              // If the user is already logged in,
              // redirect them to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the registration page
              <ClosetDetails />
            )}
          </Route>

          <Route
            // shows Closet page
            exact
            path="/editdetails/:id"
          >
            <ClosetDetailsEdit />
          </Route>

          <Route
            // shows addItem page
            exact
            path="/makeoutfit"
          >
            {!user.id ? (
              // If the user is already logged in,
              // redirect them to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the registration page
              <MakeOutfitPage />
            )}
          </Route>
          <Route
            // shows addItem page
            exact
            path="/makeoutfitcomment"
          >
            <OutfitCommentPage />
          </Route>

          <Route
            // shows addItem page
            exact
            path="/makeoutfitreview"
          >
            <OutfitReviewPage />
          </Route>
          <Route
            // shows addItem page
            exact
            path="/outfits"
          >
            {!user.id ? (
              // If the user is already logged in,
              // redirect them to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the registration page

              <OutfitsPage />
            )}
          </Route>

          {/* For protected routes, the view could show one of several things on the same route.
            Visiting localhost:3000/user will show the UserPage if the user is logged in.
            If the user is not logged in, the ProtectedRoute will show the LoginPage (component).
            Even though it seems like they are different pages, the user is always on localhost:3000/user */}
          <ProtectedRoute
            // logged in shows UserPage else shows LoginPage
            exact
            path="/user"
          >
            <UserPage />
          </ProtectedRoute>

          <ProtectedRoute
            // logged in shows InfoPage else shows LoginPage
            exact
            path="/info"
          >
            <InfoPage />
          </ProtectedRoute>

          <Route exact path="/login">
            {user.id ? (
              // If the user is already logged in,
              // redirect to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the login page
              <LoginPage />
            )}
          </Route>

          <Route exact path="/registration">
            {user.id ? (
              // If the user is already logged in,
              // redirect them to the /user page
              <Redirect to="/user" />
            ) : (
              // Otherwise, show the registration page
              <RegisterPage />
            )}
          </Route>

          {/* If none of the other routes matched, we will show a 404. */}
          <Route>
            <h1>404</h1>
          </Route>
        </Switch>

        <Footer />
        <FooterNav />
      </div>
    </Router>
  );
}

export default App;
